import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20221220-Rate-Sheet.pdf";
const date = "20.12.2022";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Tuesday 20 December 2022 we will be making the following changes to our range:</p>
    <p><strong>Summary of changes</strong></p>

    <p><strong>UK Residential Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>

    <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>

    <p><strong>UK Residential First Time Buyer Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Residential Home Mover Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Residential Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Buy to Let Purchase Range</strong></p>
    <ul>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>

    <p><strong>UK Buy to Let Remortgage Range</strong></p>
    <ul>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>

    <p><strong>International Residential Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70% and 75% LTV have reduced</li>
    </ul>

    <p>All of our fixed rate end dates will be extended as below:</p>
    <ul>
      <li>2 year fixed rates &ndash; 31 May 2025</li>
      <li>3 year fixed rates &ndash; 31 May 2026</li>
      <li>5 year fixed rates &ndash; 31 May 2028</li>
    </ul>
    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 20 December to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
